import { breakpoints, g, ng, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { getPathExtname } from '@pretto/bricks/core/utility/path/getPathExtname'
import RichContent from '@pretto/bricks/website/about/components/RichContent'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { ButtonSquare } from '@pretto/zen/reveal/atoms/buttons/buttonSquare/ButtonSquare'

import styled from 'styled-components'

export const Card = styled.div``

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(3)};
`

export const Image = styled(BaseImage).attrs(({ path }) =>
  getPathExtname(path) !== 'svg'
    ? {
        options: {
          crop: 'fill',
          height: ng(14),
        },
        sizes: `(min-width: ${breakpoints.laptop}) ${breakpoints.laptop}, 100vw`,
        srcSetBreakpoints: [
          numeralBreakpoints.mobileS,
          numeralBreakpoints.mobileM,
          numeralBreakpoints.mobileL,
          numeralBreakpoints.tablet,
          numeralBreakpoints.laptop,
          numeralBreakpoints.desktop,
        ],
      }
    : {}
)`
  display: block;
  width: 100%;
  border-radius: ${g(1)};
  margin-bottom: ${({ theme: { hasSuptitle, size } }) => (hasSuptitle || size === 'small' ? g(2) : g(3))};
  object-fit: cover;
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
`

export const Title = styled.div`
  ${({ theme }) => (theme.size === 'big' ? theme.typos.heading4 : theme.typos.heading5)};
  margin-bottom: ${({ theme }) => (theme.size === 'big' ? g(3) : g(1))};
`

export const Text = styled(RichContent)`
  ${({ theme }) => (theme.size === 'big' ? theme.typos.body4 : theme.typos.caption)};
  margin-bottom: ${({ theme }) => (theme.size === 'big' ? g(3) : g(2))};
`

export const Button = styled(ButtonSquare).attrs(({ theme }) => ({
  size: theme.size,
  scheme: 'black',
}))`
  margin-top: auto;
  margin-left: auto;
`
