import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { ServiceCardSeo } from '@pretto/website/src/components/ServiceCardSeo/ServiceCardSeo'
import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'

import styled, { css } from 'styled-components'

type IsLastProps = {
  $isLast: boolean
}

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
`

export const Suptitle = styled.div`
  ${column([2, 4])};
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`

export const Cards = styled.div`
  ${column([1, 6])};
  display: grid;
  grid-template-columns: repeat(3, calc(100% - ${g(5)}));
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  will-change: scroll-position;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    grid-template-columns: repeat(3, 1fr);
    overflow: visible;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0px !important;
  }
`

export const Card = styled(ServiceCardSeo)<IsLastProps>`
  scroll-snap-align: start;
  padding-left: ${g(2)};
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;

  ${({ $isLast }) =>
    $isLast &&
    css`
      padding-right: ${g(2)};
    `};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0px;

    & + & {
      padding-left: ${g(2)};
    }
  }
`

export const Dots = styled.div`
  ${column([2, 4])};
  display: flex;
  justify-content: center;
  margin-top: ${g(3, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
