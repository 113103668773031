import { ThemeProvider } from 'styled-components'

import * as S from './ServiceCardSeo.styles'

export type ServiceCardSeoProps = {
  description: string
  image?: string
  size?: 'small' | 'big'
  suptitle?: string
  title: string
  url: string
}

export const ServiceCardSeo: React.FC<ServiceCardSeoProps> = ({
  description,
  image,
  size = 'big',
  suptitle,
  title,
  url,
  ...props
}) => (
  <ThemeProvider theme={{ size, hasSuptitle: typeof suptitle === 'string' && suptitle.length > 1 }}>
    <S.Card {...props}>
      <S.CardContent>
        {image && <S.Image path={image} />}

        <div>
          {suptitle && size === 'big' && <S.Suptitle>{suptitle}</S.Suptitle>}
          <S.Title>{title}</S.Title>
          <S.Text allowedTypes={['strong']}>{description}</S.Text>
        </div>

        <S.Button href={url} />
      </S.CardContent>
    </S.Card>
  </ThemeProvider>
)
