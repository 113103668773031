import PaginationDots from '@pretto/bricks/components/utility/PaginationDots'

import { useCarousel } from '@pretto/zen/reveal/lib/useCarousel'

import { ServiceCardSeoProps } from '@pretto/website/src/components/ServiceCardSeo/ServiceCardSeo'

import { useRef } from 'react'

import * as S from './RowCards.styles'

export type RowCardsProps = {
  entries: ServiceCardSeoProps[]
  subtitle?: string
}

export const RowCards: React.FC<RowCardsProps> = ({ entries, subtitle, ...props }) => {
  const refScrollingContainer = useRef<HTMLDivElement>(null)

  const { currentPageIndex, scrollToIndex } = useCarousel({
    container: refScrollingContainer,
    numberOfSlides: entries.length,
  })

  const handleDotClick = (index: number) => {
    scrollToIndex(index)
  }

  return (
    <S.NewBlocRow {...props}>
      {subtitle && <S.Suptitle>{subtitle}</S.Suptitle>}

      <S.Cards ref={refScrollingContainer}>
        {entries.map((card, index) => (
          <S.Card $isLast={index === entries.length - 1} {...card} key={index} />
        ))}
      </S.Cards>

      <S.Dots>
        <PaginationDots
          activeVariant="primary1"
          currentIndex={currentPageIndex}
          inactiveVariant="neutral2"
          onClick={handleDotClick}
          length={entries.length}
          visibleLength={3}
        />
      </S.Dots>
    </S.NewBlocRow>
  )
}
